:root {
    --app-background: #243965;
    --app-border-color: #a9a8a8;
    --app-border-size: 2px;
}
html {
    background-color: var(--app-background);
}

body {
    background-color: var(--app-background);
    color: #fff;
}

h3 {
    margin: 0;
}

.navbar-dark .navbar-brand {
    color: #ccc;
}

a {
    color: #fff;
}

.arrow-up {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid green;
}

.arrow-down {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid red;
}

.modal-content,
.modal-body,
.modal-header,
.modal-footer {
    background-color: #292929;
}

.modal-header {
    border-bottom-color: var(--app-border-color);
}

.modal-footer {
    border-top-color: var(--app-border-color);
}

html,
body {
    height: 100%;
}

.form-login {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;

    margin-top: 40px;
}
.form-login .checkbox {
    font-weight: 400;
}
.form-login .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-login input[type='email'] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-login input[type='password'] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-control {
    background: #181717;
    color: #ccc;
}
