/*
td,
th {
    
    
}

th:first-child,
td:first-child {
    border-left: 1px solid var(--app-border-color);
}

th:last-child,
td:last-child {
    border-right: 1px solid var(--app-border-color);
}
*/

.table {
    width: 100%;
    border: var(--app-border-size) solid var(--app-border-color);
    padding: 0;
}

.table td,
.table th {
    padding: 0.6rem;
}

.table td {
    border-top: var(--app-border-size) solid var(--app-border-color);
}

.table td {
    border-right: var(--app-border-size) solid var(--app-border-color);
}

.table td:last-child {
    border-right: none;
}

.table tr:hover {
    background-color: #444444;
}

.table tr {
    background-color: #243965;
}

.table tr:nth-child(even) {
    background-color: #182644;
}
